@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {

    :root {
        --theme-bg                          : theme('colors.white');
        --theme-text                        : theme('colors.black');
        --theme-button-1-bg                 : theme('colors.rose.300');
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.rose.100');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.rose.500');
        --theme-button-1-disabled-bg        : theme('colors.rose.200');
        --theme-button-1-disabled-text      : theme('colors.rose.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.charcoal.800');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.200');
        --theme-link-text                   : theme('colors.rose.200');
        --theme-subLabel                    : theme('colors.midnight.200');
        --theme-action                      : theme('colors.midnight.200');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.charcoal.800');
        --theme-reverse-text                : theme('colors.charcoal.50');
        --theme-reverse-link-text           : theme('colors.midnight.500');
        --theme-tag-inactive-text           : theme('colors.midnight.600');
        --theme-tag-inactive-bg             : theme('colors.midnight.100');
    }

    [data-theme='charcoal-light'] {
        --theme-bg                          : theme('colors.charcoal.50');
        --theme-text                        : theme('colors.charcoal.800');
        --theme-button-1-bg                 : theme('colors.midnight.700');
        --theme-button-1-text               : theme('colors.white');
        --theme-button-1-hover-bg           : theme('colors.midnight.800');
        --theme-button-1-hover-text         : theme('colors.white');
        --theme-button-1-active-bg          : theme('colors.midnight.600');
        --theme-button-1-disabled-bg        : theme('colors.midnight.300');
        --theme-button-1-disabled-text      : theme('colors.midnight.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.charcoal.800');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.200');
        --theme-link-text                   : theme('colors.midnight.500');
        --theme-subLabel                    : theme('colors.charcoal.700');
        --theme-action                      : theme('colors.midnight.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.charcoal.800');
        --theme-reverse-text                : theme('colors.white');
        --theme-reverse-link-text           : theme('colors.rose.200');
        --theme-tag-inactive-text           : theme('colors.midnight.600');
        --theme-tag-inactive-bg             : theme('colors.midnight.100');

    }

    [data-theme='charcoal-dark'] {
        --theme-bg                          : theme('colors.charcoal.800');
        --theme-text                        : theme('colors.white');
        --theme-button-1-bg                 : theme('colors.rose.300');
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.rose.100');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.rose.500');
        --theme-button-1-disabled-bg        : theme('colors.rose.200');
        --theme-button-1-disabled-text      : theme('colors.rose.500');
        --theme-button-outline              : theme('colors.white');
        --theme-hr                          : theme('colors.white');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.600');
        --theme-link-text                   : theme('colors.rose.200');
        --theme-subLabel                    : theme('colors.charcoal.400');
        --theme-action                      : theme('colors.charcoal.50');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.charcoal.100');
        --theme-reverse-text                : theme('colors.charcoal.800');
        --theme-reverse-link-text           : theme('colors.midnight.500');
        --theme-tag-inactive-text           : theme('colors.rose.500');
        --theme-tag-inactive-bg             : theme('colors.rose.200');
    }

    [data-theme='coral-light'] {
        --theme-bg                          : theme('colors.coral.100');
        --theme-text                        : theme('colors.charcoal.800');
        --theme-button-1-bg                 : theme('colors.midnight.700');
        --theme-button-1-text               : theme('colors.white');
        --theme-button-1-hover-bg           : theme('colors.midnight.800');
        --theme-button-1-hover-text         : theme('colors.white');
        --theme-button-1-active-bg          : theme('colors.midnight.600');
        --theme-button-1-disabled-bg        : theme('colors.midnight.300');
        --theme-button-1-disabled-text      : theme('colors.midnight.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.charcoal.800');
        --theme-hr-highlight                : theme('colors.coral.500');
        --theme-hr-subtle                   : theme('colors.charcoal.200');
        --theme-link-text                   : theme('colors.midnight.500');
        --theme-subLabel                    : theme('colors.coral.800');
        --theme-action                      : theme('colors.coral.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.rose.900');
        --theme-reverse-text                : theme('colors.white');
        --theme-reverse-link-text           : theme('colors.rose.200');
        --theme-tag-inactive-text           : theme('colors.midnight.600');
        --theme-tag-inactive-bg             : theme('colors.midnight.100');
    }

    [data-theme='coral-dark'] {
        --theme-bg                          : theme('colors.rose.900');
        --theme-text                        : theme('colors.white');
        --theme-button-1-bg                 : theme('colors.rose.300');
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.rose.100');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.rose.500');
        --theme-button-1-disabled-bg        : theme('colors.rose.200');
        --theme-button-1-disabled-text      : theme('colors.rose.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.white');
        --theme-hr-highlight                : theme('colors.rose.500');
        --theme-hr-subtle                   : theme('colors.charcoal.600');
        --theme-link-text                   : theme('colors.rose.200');
        --theme-subLabel                    : theme('colors.rose.100');
        --theme-action                      : theme('colors.rose.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.coral.100');
        --theme-reverse-text                : theme('colors.charcoal.800');
        --theme-reverse-link-text           : theme('colors.midnight.500');
        --theme-tag-inactive-text           : theme('colors.rose.500');
        --theme-tag-inactive-bg             : theme('colors.rose.200');
    }

    [data-theme='rose-light'] {
        --theme-bg                          : theme('colors.rose.100');
        --theme-text                        : theme('colors.charcoal.800');
        --theme-button-1-bg                 : theme('colors.midnight.700');
        --theme-button-1-text               : theme('colors.white');
        --theme-button-1-hover-bg           : theme('colors.midnight.800');
        --theme-button-1-hover-text         : theme('colors.white');
        --theme-button-1-active-bg          : theme('colors.midnight.600');
        --theme-button-1-disabled-bg        : theme('colors.midnight.300');
        --theme-button-1-disabled-text      : theme('colors.midnight.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.charcoal.800');
        --theme-hr-highlight                : theme('colors.emerald.500');
        --theme-hr-subtle                   : theme('colors.charcoal.200');
        --theme-link-text                   : theme('colors.midnight.500');
        --theme-subLabel                    : theme('colors.rose.900');
        --theme-action                      : theme('colors.rose.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.coral.800');
        --theme-reverse-text                : theme('colors.rose.200');
        --theme-reverse-link-text           : theme('colors.rose.200');
        --theme-tag-inactive-text           : theme('colors.midnight.600');
        --theme-tag-inactive-bg             : theme('colors.midnight.100');
    }

    [data-theme='rose-dark'] {
        --theme-bg                          : theme('colors.rose.800');
        --theme-text                        : theme('colors.rose.200');
        --theme-button-1-bg                 : theme('colors.rose.300');
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.rose.100');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.rose.500');
        --theme-button-1-disabled-bg        : theme('colors.rose.200');
        --theme-button-1-disabled-text      : theme('colors.rose.500');
        --theme-button-outline              : theme('colors.white');
        --theme-hr                          : theme('colors.white');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.600');
        --theme-link-text                   : theme('colors.midnight.500');
        --theme-subLabel                    : theme('colors.rose.900');
        --theme-link-text                   : theme('colors.rose.200');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.rose.100');
        --theme-reverse-text                : theme('colors.charcoal.800');
        --theme-reverse-link-text           : theme('colors.rose.200');
        --theme-tag-inactive-text           : theme('colors.rose.500');
        --theme-tag-inactive-bg             : theme('colors.rose.200');
    }

    [data-theme='emerald-light'] {
        --theme-bg                          : theme('colors.emerald.50');
        --theme-text                        : theme('colors.charcoal.800');
        --theme-button-1-bg                 : theme('colors.midnight.700');
        --theme-button-1-text               : theme('colors.white');
        --theme-button-1-hover-bg           : theme('colors.midnight.800');
        --theme-button-1-hover-text         : theme('colors.white');
        --theme-button-1-active-bg          : theme('colors.midnight.600');
        --theme-button-1-disabled-bg        : theme('colors.midnight.300');
        --theme-button-1-disabled-text      : theme('colors.midnight.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.charcoal.800');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.200');
        --theme-link-text                   : theme('colors.midnight.500');
        --theme-subLabel                    : theme('colors.charcoal.700');
        --theme-action                      : theme('colors.midnight.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.emerald.800');
        --theme-reverse-text                : theme('colors.white');
        --theme-reverse-link-text           : theme('colors.rose.200');
        --theme-tag-inactive-text           : theme('colors.midnight.600');
        --theme-tag-inactive-bg             : theme('colors.midnight.100');
    }

    [data-theme='emerald-dark'] {
        --theme-bg                          : theme('colors.emerald.800');
        --theme-text                        : theme('colors.white');
        --theme-button-1-bg                 : theme('colors.rose.300');
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.rose.100');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.rose.500');
        --theme-button-1-disabled-bg        : theme('colors.rose.200');
        --theme-button-1-disabled-text      : theme('colors.rose.500');
        --theme-button-outline              : theme('colors.white');
        --theme-hr                          : theme('colors.white');
        --theme-hr-highlight                : theme('colors.emerald.500');
        --theme-hr-subtle                   : theme('colors.charcoal.600');
        --theme-link-text                   : theme('colors.rose.200');
        --theme-subLabel                    : theme('colors.emerald.100');
        --theme-action                      : theme('colors.emerald.200');
        --theme-swiper-progress-bar         : theme('colors.emerald.800');
        --theme-swiper-progress-fill        : theme('colors.emerald.200');
        --theme-reverse-bg                  : theme('colors.rose.50');
        --theme-reverse-text                : theme('colors.charcoal.800');
        --theme-reverse-link-text           : theme('colors.midnight.500');
        --theme-tag-inactive-text           : theme('colors.rose.500');
        --theme-tag-inactive-bg             : theme('colors.rose.200');
    }

    [data-theme='midnight-light'] {
        --theme-bg                          : theme('colors.midnight.50');
        --theme-text                        : theme('colors.charcoal.800');
        --theme-button-1-bg                 : theme('colors.midnight.700');
        --theme-button-1-text               : theme('colors.white');
        --theme-button-1-hover-bg           : theme('colors.midnight.800');
        --theme-button-1-hover-text         : theme('colors.white');
        --theme-button-1-active-bg          : theme('colors.midnight.600');
        --theme-button-1-disabled-bg        : theme('colors.midnight.300');
        --theme-button-1-disabled-text      : theme('colors.midnight.500');
        --theme-button-outline              : theme('colors.midnight.700');
        --theme-hr                          : theme('colors.charcoal.800');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.200');
        --theme-link-text                   : theme('colors.midnight.500');
        --theme-subLabel                    : theme('colors.midnight.800');
        --theme-action                      : theme('colors.midnight.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.midnight.700');
        --theme-reverse-text                : theme('colors.white');
        --theme-reverse-link-text           : theme('colors.rose.200');
        --theme-tag-inactive-text           : theme('colors.midnight.600');
        --theme-tag-inactive-bg             : theme('colors.midnight.100');
    }

    [data-theme='midnight-dark'] {
        --theme-bg                          : theme('colors.midnight.700');
        --theme-text                        : theme('colors.white');
        --theme-button-1-bg                 : theme('colors.rose.300');
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.rose.100');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.rose.500');
        --theme-button-1-disabled-bg        : theme('colors.rose.200');
        --theme-button-1-disabled-text      : theme('colors.rose.500');
        --theme-button-outline              : theme('colors.white');
        --theme-hr                          : theme('colors.white');
        --theme-hr-highlight                : theme('colors.midnight.500');
        --theme-hr-subtle                   : theme('colors.charcoal.600');
        --theme-link-text                   : theme('colors.rose.200');
        --theme-subLabel                    : theme('colors.midnight.200');
        --theme-action                      : theme('colors.midnight.500');
        --theme-swiper-progress-bar         : theme('colors.rose.100');
        --theme-swiper-progress-fill        : theme('colors.rose.500');
        --theme-reverse-bg                  : theme('colors.midnight.50');
        --theme-reverse-text                : theme('colors.charcoal.800');
        --theme-reverse-link-text           : theme('colors.midnight.500');
        --theme-tag-inactive-text           : theme('colors.rose.500');
        --theme-tag-inactive-bg             : theme('colors.rose.200');
    }

    /* Button theme */
    [data-theme='gray-dark'] {
        --theme-button-1-bg                 : rgba(57, 70, 78, 0.6);
        --theme-button-1-text               : theme('colors.white');
        --theme-button-1-hover-bg           : theme('colors.charcoal.800');
        --theme-button-1-hover-text         : theme('colors.white');
        --theme-button-1-active-bg          : theme('colors.charcoal.900');
        --theme-button-1-disabled-bg        : theme('colors.charcoal.500');
        --theme-button-1-disabled-text      : theme('colors.charcoal.50');
    }

    /* Button theme */
    [data-theme='gray-light'] {
        --theme-button-1-bg                 : rgba(249, 247, 245, 0.60);
        --theme-button-1-text               : theme('colors.charcoal.800');
        --theme-button-1-hover-bg           : theme('colors.charcoal.50');
        --theme-button-1-hover-text         : theme('colors.charcoal.800');
        --theme-button-1-active-bg          : theme('colors.white');
        --theme-button-1-disabled-bg        : theme('colors.charcoal.500');
        --theme-button-1-disabled-text      : theme('colors.charcoal.50');
    }

    /* Add font smoothing as per ryan's request */
    html { 
        scroll-behavior: smooth;
        -webkit-font-smoothing: antialiased;
    }
    body {
        @apply overflow-x-hidden;
    }
    :target {
        /* rem unit will break the scroll */
        scroll-margin-top: 200px;
        scroll-margin-top: 15vh;
    }
    ._leading-search {
        line-height: 1.3 !important;
    }
    ._icon-stroke path {
        stroke: currentColor;
        @apply stroke-1;
    }

    /* Added for animations on scroll reveal */
    ._image-border {
        --image-animation-border-size: 24px;
        box-shadow: 0 0 0 60px var(--theme-bg);
        pointer-events: none;
    }
    @media (min-width: 768px) {
        ._image-border {
            --image-animation-border-size: 40px;
        }
    }
    ._cta-animation-distance {
        --cta-animation-distance: 0px;
        @media (min-width: 768px) {
            --cta-animation-distance: 32px;
        }
    }
}

@layer components {
    @import 'swiper/css';
    @import 'swiper/css/navigation';
    @import 'swiper/css/pagination';
    @import 'swiper/css/effect-fade';

    /* RTE specific styles */
    ._rte a {
        @apply underline text-[--theme-link-text];
        text-decoration-thickness: 1px;
    }

    ._rte strong {
        @apply font-semibold;
    }

    ._rte p,
    ._rte ul,
    ._rte ol {
        @apply mb-4 last:mb-0;
    }
    ._rte figcaption {
        @apply mb-4;
    }

    ._rte ul {
        @apply list-disc pl-8;
    }

    ._rte li {
        @apply has-[h2]:font-serif has-[h2]:text-4xl has-[h2]:sm:text-5xl has-[h2]:font-medium has-[h3]:font-serif has-[h3]:text-3xl has-[h4]:text-base has-[h4]:font-normal has-[h4]:text-[--theme-subLabel];
    }

    ._rte ol {
        @apply list-decimal pl-8;
    }

    /* Added to achieve custom fix for centered list items inside RTE */
    .text-center ._rte ul li {
        @apply inline-flex px-2 before:content-[""] before:size-[0.25em] before:rounded-full before:bg-[--theme-text] before:mr-3 before:mt-2.5 before:inline-flex ;
    }

    .text-center ._rte ol li {
        counter-increment: step-counter;
        @apply inline-flex px-2 before:mr-3 before:inline-flex;
    }

    .text-center ._rte ol li:before {
       content: counter(step-counter)'.';
    }

    ._rte h3 {
        @apply text-3xl font-serif mb-2;
    }
    ._rte h2 {
        @apply text-4xl sm:text-5xl font-serif font-medium mb-4;
    }
    ._rte h4 {
        @apply text-base font-normal mb-1.5 text-[--theme-subLabel];
    }
    ._rte blockquote {
        @apply text-3xl mx-auto py-8  px-8 md:px-10 max-w-3xl font-serif before:content-[''] before:w-16 before:h-10 before:block before:mx-auto before:mb-4;
        text-align: center;
    }
    ._rte blockquote:before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='61' height='40' viewBox='0 0 61 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M39.6679 21.5603C41.256 19.7163 43.999 18.5816 47.6083 18.5816C53.2388 18.5816 57.8586 22.695 57.8586 28.7943C57.8586 35.461 52.6613 40 45.7315 40C37.5023 40 32.0163 33.617 32.0163 24.2553C32.0163 9.3617 44.2878 0.141842 60.0242 0V2.26951C48.4745 3.26241 38.8017 9.3617 39.6679 21.5603ZM8.62821 21.5603C10.2163 19.7163 12.9593 18.5816 16.5686 18.5816C22.1991 18.5816 26.8189 22.695 26.8189 28.7943C26.8189 35.461 21.6216 40 14.6918 40C6.46265 40 0.976562 33.617 0.976562 24.2553C0.976562 9.3617 13.2481 0.141842 28.9845 0V2.26951C17.4348 3.26241 7.76199 9.3617 8.62821 21.5603Z' fill='%2339464E'/%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
    }
    ._rte--page img {
        @apply w-full rounded-2xl my-5;
    }

    ._rte--page hr {
        @apply border-[--theme-hr-subtle];
    }
    ._rte--page h2 {
        @apply text-4xl;
    }
    ._rte--page h4 {
        @apply font-semibold mb-4 mt-0.5;
        font-size: inherit;
        color: inherit;
    }

    /* Added to override pre responsive styling for ._rte */
    ._rte._rte--page li {
        @apply has-[h2]:text-4xl has-[h2]:sm:text-4xl;
    }

    ._rte .embeditem {
        @apply relative aspect-video w-full;
    }

    ._rte .embeditem iframe {
        @apply absolute inset-0 w-full h-full object-cover;
    }

    ._rte--page blockquote em {
        @apply not-italic block mt-4 text-center text-base font-sans font-medium text-[--theme-link-text];
    }

    ._media-inactive button {
        @apply hidden;
    }

    ._rte .tiktok-embed {
        @apply px-0;
        font-size: unset;
        font-family: unset;
    }
    
    ._rte .tiktok-embed:before {
        display: none;
    }

    ._rte .instagram-media {
        margin: 24px auto !important;
    }

    ._mobile-main-menu ._mobile-stagger {
        @apply translate-y-[24px] opacity-0;
        will-change: opacity, transform;
    }

    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger {
        animation: forwards mobileStagger 0.4s ease-out;
    }
    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger:nth-child(1) {
        animation-delay: 0.3s;
    }
    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger:nth-child(2) {
        animation-delay: 0.4s;
    }
    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger:nth-child(3) {
        animation-delay: 0.5s;
    }
    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger:nth-child(4) {
        animation-delay: 0.6s;
    }
    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger:nth-child(5) {
        animation-delay: 0.7s;
    }
    [data-headlessui-state="open"]._mobile-main-menu ._mobile-stagger:nth-child(6) {
        animation-delay: 0.8s;
    }

    @keyframes mobileStagger {
        0% {
            transform: translateY(24px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.debug,
.debug * {
    border: 1px solid green;
}

/* Prevent display classes to show hidden element */
[hidden] {
    display: none !important;
}

/* swiper specific styles (Don't move these as they will not be created unless kept outside of @layer) */
[data-style='carousel'] .swiper-pagination {
    @apply relative mt-4 md:hidden;
}

[data-style='carousel'] .swiper-button-prev,
[data-style='carousel'] .swiper-button-next {
    @apply hidden;
}

[data-style='carousel'] .swiper-pagination-progressbar,
[data-style='carousel'] .swiper-pagination-progressbar-fill{
    @apply rounded-full overflow-hidden;
}

[data-style='carousel'] .swiper-pagination-progressbar {
    @apply w-52 mx-auto bg-[--theme-swiper-progress-bar];
}

[data-style='carousel'] .swiper-pagination-progressbar-fill {
    @apply bg-[--theme-swiper-progress-fill]
}

.swiper-button-prev,
.swiper-button-next,
[data-navigation='prev'],
[data-navigation='next'] {
    @apply select-none relative transition duration-200 inline-flex justify-center items-center font-medium before:absolute before:content-[""] before:w-full before:transition-all before:duration-200 disabled:bg-[--theme-button-1-disabled-bg] disabled:text-[--theme-button-1-disabled-text] disabled:pointer-events-none [&.disabled]:bg-[--theme-button-1-disabled-bg] [&.disabled]:text-[--theme-button-1-disabled-text] [&.disabled]:pointer-events-none
    bg-[--theme-button-1-bg] px-8 text-[--theme-button-1-text] hover:-translate-y-1 hover:-translate-x-1 hover:bg-[--theme-button-1-hover-bg] rounded-full
    [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-y-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-x-0
    [@media(hover:hover)]:[@media(pointer:fine)]:active:bg-[--theme-button-1-active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:active:before:opacity-0
    before:h-full before:block before:rounded-full before:opacity-0 hover:before:opacity-100 before:shadow-button py-4 pl-4 pr-4 after:hidden
    active:-translate-y-1 active:bg-[--theme-button-1-hover-bg] active:-translate-x-1 active:before:opacity-100
    ;
}

/* TODO: Move to tailwind logic without breaking if possible */
/* @alex I have noticed if I update backface to tailwind it breaks also perspective behaves differently when I try use 'perspective-midrange' */
/* I have left here because I was spending too much time on it, please update hover to click and whatever else you can without breaking the effect */
._card {
    -webkit-perspective: 150rem;
            perspective: 150rem;
    -moz-perspective: 150rem;
}

._card-flip ._card-front {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}

._card-flip ._card-back {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}

._card-back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

._card-back,
._card-front {
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}


/* Marketo form override styling */
[data-style='marketo-form'] .mktoForm {
    @apply bg-white relative z-10;
    font-family: 'halyard-display', sans-serif !important;
    width: 100% !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

[data-style='marketo-form'] .mktoForm .mktoFieldWrap,
[data-style='marketo-form'] .mktoForm * {
    float: none !important;
}

[data-style='marketo-form'] .mktoForm .mktoFormCol {
    float: none !important;
    min-height: 0 !important
}

[data-style='marketo-form'] .mktoForm input,
[data-style='marketo-form'] .mktoForm textarea,
[data-style='marketo-form'] .mktoForm select {
    @apply appearance-none bg-white;
    position: relative !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    width: 100% !important;
    height: auto !important;
    box-shadow: none !important;
    background: none !important;
}

[data-style='marketo-form'] input:focus,
[data-style='marketo-form'] input:focus,
[data-style='marketo-form'] input:focus {
    border-color: transparent !important;
    outline: 2px solid theme('colors.midnight.600') !important;
}

/* add :not for display none css
 */
[data-style='marketo-form'] .mktoRequiredField:has(.mktoError:not([style*='display: none'])) input,
[data-style='marketo-form'] .mktoRequiredField:has(.mktoError:not([style*='display: none'])) textarea,
[data-style='marketo-form'] .mktoRequiredField:has(.mktoError:not([style*='display: none'])) select,
[data-style='marketo-form'] .mktoRequiredField:has(.mktoError:not([style*='display: none'])) input:focus,
[data-style='marketo-form'] .mktoRequiredField:has(.mktoError:not([style*='display: none'])) textarea:focus,
[data-style='marketo-form'] .mktoRequiredField:has(.mktoError:not([style*='display: none'])) select:focus {
    border: 1px solid theme('colors.coral.400') !important;
    outline: 0 !important;
}

[data-style='marketo-form'] .mktoForm select {
    @apply cursor-pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.86669 5.35081C3.09556 5.13696 3.40249 5.02011 3.72004 5.02593C4.03758 5.03175 4.33975 5.15976 4.56015 5.38184L10.4872 11.4917L16.4143 5.38184C16.5221 5.26649 16.6525 5.17332 16.798 5.10784C16.9435 5.04237 17.1011 5.00592 17.2613 5.00066C17.4216 4.9954 17.5813 5.02144 17.731 5.07723C17.8808 5.13302 18.0174 5.21742 18.1329 5.32545C18.2484 5.43347 18.3404 5.56291 18.4035 5.70609C18.4665 5.84928 18.4994 6.0033 18.5 6.15903C18.5006 6.31476 18.4691 6.46903 18.4072 6.6127C18.3453 6.75637 18.2543 6.88651 18.1397 6.99541L11.3499 13.9772C11.2382 14.0899 11.1042 14.1794 10.9559 14.2406C10.8077 14.3018 10.6483 14.3333 10.4872 14.3333C10.3262 14.3333 10.1668 14.3018 10.0185 14.2406C9.87031 14.1794 9.7363 14.0899 9.62453 13.9772L2.83474 6.99541C2.61455 6.77315 2.49422 6.47507 2.50021 6.16669C2.5062 5.85831 2.63802 5.56485 2.86669 5.35081Z' fill='%2339464E'/%3e%3c/svg%3e ") !important;
    background-position: right 0.5rem center !important;
    background-repeat: no-repeat !important;
}

[data-style='marketo-form'] .mktoForm select option {
    padding-left: 0 !important;
}

[data-style='marketo-form'] .mktoCheckboxList {
    width: 100% !important;
}

[data-style='marketo-form'] .mktoForm input[type='checkbox'] {
    position: absolute !important;
    top: -100% !important;
    left: -100% !important;
    visibility: hidden !important;
}

[data-style='marketo-form'] .mktoForm input[type='checkbox'] + label {
    @apply flex relative pl-8 text-base cursor-pointer;
    position: relative !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex !important;
    position: relative;
    display: flex !important;
    cursor: pointer !important;
    padding-left: 2rem !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

[data-style='marketo-form'] .mktoForm input[type='checkbox'] + label:before {
    @apply content-[""] block absolute top-0.5 left-0 w-5 h-5 border border-charcoal-200 bg-white cursor-pointer;
    border-radius: 2px;
    margin-left: 0 !important;
    box-shadow: none !important;
    line-height: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
}

[data-style='marketo-form'] .mktoForm input[type='checkbox'] + label:after {
    @apply content-[""] block absolute top-0.5 left-0 w-5 h-5 border border-transparent pointer-events-none opacity-0 bg-no-repeat bg-center;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_9513_14310)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.1607 1.1745C16.2506 1.24274 16.3261 1.32803 16.383 1.42549C16.4399 1.52295 16.477 1.63067 16.4922 1.74249C16.5074 1.85431 16.5004 1.96803 16.4716 2.07714C16.4428 2.18625 16.3927 2.2886 16.3243 2.37835L7.16958 14.394C7.09532 14.4913 7.0011 14.5716 6.89324 14.6295C6.78538 14.6874 6.66638 14.7216 6.54423 14.7297C6.42207 14.7379 6.2996 14.7198 6.18501 14.6767C6.07043 14.6336 5.9664 14.5664 5.8799 14.4798L0.730347 9.33025C0.578744 9.16755 0.496211 8.95236 0.500134 8.73002C0.504057 8.50767 0.59413 8.29552 0.751378 8.13828C0.908626 7.98103 1.12077 7.89095 1.34312 7.88703C1.56547 7.88311 1.78066 7.96564 1.94335 8.11725L6.39943 12.5722L14.9591 1.33814C15.0969 1.15728 15.3008 1.03847 15.5261 1.00779C15.7513 0.977113 15.9796 1.03707 16.1607 1.1745Z' fill='white'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_9513_14310'%3e%3crect width='16' height='16' fill='white' transform='translate(0.5)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e") !important;
}

[data-style='marketo-form'] .mktoForm input[type='checkbox']:checked + label:before {
    @apply bg-midnight-700 border-midnight-700;
}

[data-style='marketo-form'] .mktoForm input[type='checkbox']:checked + label:after {
    @apply opacity-100;
}

[data-style='marketo-form'] .mktoFormCol {
    margin-bottom: 20px !important;
}

[data-style='marketo-form'] .mktoLabel {
    display: flex !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    width: 100% !important;
    margin-bottom: 4px !important;
    padding-top: 0 !important;
}
[data-style='marketo-form'] .mktoCheckboxList {
    padding: 0 !important;
}

[data-style='marketo-form'] .mktoAsterix {
    @apply ml-1.5 order-2;
}

[data-style='marketo-form'] .mktoAsterix,
[data-style='marketo-form'] .mktoForm .mktoClear,
/* Commented as needs to be better tested first */
/* [data-style='marketo-form'] .mktoForm .mktoErrorDetail, */
[data-style='marketo-form'] .mktoForm .mktoGutter,
[data-style='marketo-form'] .mktoForm .mktoOffset,
[data-style='marketo-form'] .mktoForm .mktoPlaceholder {
    display: none !important;
}

[data-style='marketo-form'] .mktoForm .mktoRequiredField>label.mktoLabel:after {
    content: "*" !important;
    display: inline-block !important;
    color: inherit !important;
    font-weight: inherit !important;
    padding-left: .25rem !important;
}

[data-style='marketo-form'] .mktoButtonWrap {
    margin-left: 0 !important;
}

[data-style='marketo-form'] .mktoField {
    @apply border border-charcoal-200;
    width: 100% !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}

/* Specific for referal form */
[data-style='marketo-form'] .mktoFieldWrap:has(#AP_Referrer_ID__c_contact),
[data-style='marketo-form'] .mktoFormCol legend {
    display: none !important;
}

.mktoErrorMsg {
    font-size: 12px !important;
    line-height: 16px !important;
    background-image: none !important;
    background-color: transparent !important;
    display: block;
    margin-top: unset !important;
    border: 0 !important;
    box-shadow: none !important;
    color: inherit !important;
    max-width: none !important;
    padding: unset !important;
    text-shadow: none !important;
}

[data-style='marketo-form'] .mktoError {
    position: static !important;
    color: inherit !important;
}

[data-style='marketo-form'] .mktoErrorArrowWrap {
    display: none !important;
}

[data-style='marketo-form'] .mktoErrorMsg:before {
    @apply content-[""] w-5 h-5 mr-1 inline-flex relative -bottom-1.5;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z' fill='%23FF616A'/%3e%3c/svg%3e");
}

[data-style='marketo-form'] .mktoCaptchaDisclaimer {
    @apply text-sm;
}

[data-style='marketo-form'] .mktoForm .mktoError .mktoErrorMsg .mktoErrorDetail {
    display: inline-block !important;
}

[data-style='marketo-form'] .mktoCaptchaDisclaimer a {
    @apply text-midnight-500 underline;
}

[data-style='marketo-form'] .mktoButtonRow {
    @apply pt-10;
    display: block !important;
 }

 [data-style='marketo-form'] .mktoForm .mktoButtonWrap.mktoSimple .mktoButton,
 [data-style='marketo-form'] .mktoForm .mktoButtonWrap.mktoGlow .mktoButton,
 [data-style='marketo-form'] .mktoForm .mktoButtonWrap .mktoButton {
     @apply shrink-0 select-none relative transition duration-200 inline-flex justify-center items-center
     font-medium before:absolute before:content-[""] before:w-full before:transition-all before:duration-200
     [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-y-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-x-0 [@media(hover:hover)]:[@media(pointer:fine)]:focus:translate-y-0 [@media(hover:hover)]:[@media(pointer:fine)]:focus:translate-x-0
     pt-[14px] pb-[18px] px-8 text-base w-full
     bg-[--theme-button-1-bg] text-[--theme-button-1-text] [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:bg-[--theme-button-1-hover-bg] will-change-transform rounded-full
     [@media(hover:hover)]:[@media(pointer:fine)]:focus:bg-[--theme-button-1-[@media(hover:hover)]:[@media(pointer:fine)]:active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:focus:before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:bg-[--theme-button-1-[@media(hover:hover)]:[@media(pointer:fine)]:active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:active:before:opacity-0
     before:h-full before:block before:rounded-full before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:hover:before:opacity-100 before:shadow-button
     disabled:bg-[--theme-button-1-disabled-bg] disabled:text-[--theme-button-1-disabled-text] disabled:pointer-events-none
     [&.disabled]:bg-[--theme-button-1-disabled-bg] [&.disabled]:text-[--theme-button-1-disabled-text] [&.disabled]:pointer-events-none
     active:bg-[--theme-button-1-hover-bg] active:-translate-x-1 active:before:opacity-100 active:-translate-y-1;
     background-image: none !important;
     border: 0 !important;
     box-shadow: none !important;
 }

/* _lum keeping commented for referenmce only */
/* style='mask-type:luminance' */


/* 
    Osano. 22/01/2025
    At the moment Osano javascript is been loaded using Umbraco SEO Toolkit
    https://docs.osano.com/hc/en-us/articles/22469396583316-CSS-for-Customizing-Styling-Osano-Cookie-Consent
*/

[class*=osano-cm] {
    @apply text-base font-light;
    font-family: 'halyard-display', sans-serif !important;
}

.osano-cm-button {
    @apply font-medium py-2 px-4 text-sm rounded-full 
}

.osano-cm-link {
    @apply no-underline hover:underline;
    font: inherit;
}

.osano-cm-header {
    @apply font-semibold text-lg;
}

.osano-cm-toggle {
    @apply font-medium;
}

.osano-cm-widget svg {
    @apply md:size-6
}

.osano-cm-label {
    @apply font-semibold;
}
